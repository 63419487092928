@import '../color.scss';

#dialog, form{
    .err, .ok{
        b{color: $cor_err}
        b::after{
            content:'\f00d';
            color:$cor_err;
            margin: 0 1em;
            font-weight: 900;
            position: absolute;
            font-family: 'Font Awesome 5 Free'
        }
        .input-group .input-group-btn .btn.sel{
            color: #FEFFFF;
            background-color: $cor_err;
        }
    }
    .ok{
        b{color: $cor_ok}
        b::after{
            content:'\f00c';
            color: $cor_ok;
        }
    }
}