@charset "UTF-8";
#form, .form {
  padding: 0;
  min-width: 300px; }
  #form .step2, .form .step2 {
    margin-top: 2.1em; }
  #form .title, .form .title {
    font-size: 1.3em;
    margin-bottom: 1em;
    text-indent: 0.5em; }
    #form .title.top, .form .title.top {
      margin-top: 0; }
  #form.box, .form.box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #form.box .gLabel, #form.box label, .form.box .gLabel, .form.box label {
      flex-shrink: 0;
      margin-bottom: 1em;
      font-weight: 300; }
      #form.box .gLabel .btn-group .btn, #form.box label .btn-group .btn, .form.box .gLabel .btn-group .btn, .form.box label .btn-group .btn {
        height: 38px;
        padding: 0; }
      #form.box .gLabel span, #form.box label span, .form.box .gLabel span, .form.box label span {
        position: absolute;
        padding: 5px;
        color: #888;
        font-size: 0.8em; }
        #form.box .gLabel span.right, #form.box label span.right, .form.box .gLabel span.right, .form.box label span.right {
          margin-left: -4em;
          margin-top: 0.3em; }
      #form.box .gLabel b, #form.box label b, .form.box .gLabel b, .form.box label b {
        display: block;
        font-size: 0.85em;
        margin-bottom: 5px;
        color: #0D0D0D;
        font-weight: 400; }
      #form.box .gLabel input, #form.box .gLabel textarea, #form.box label input, #form.box label textarea, .form.box .gLabel input, .form.box .gLabel textarea, .form.box label input, .form.box label textarea {
        width: 100%;
        height: 38px;
        color: #0D0D0D;
        font-size: 0.9em;
        border: 1px solid #DDD;
        border-radius: 4px;
        text-indent: 0.8em;
        padding-left: 0; }
        #form.box .gLabel input:focus, #form.box .gLabel textarea:focus, #form.box label input:focus, #form.box label textarea:focus, .form.box .gLabel input:focus, .form.box .gLabel textarea:focus, .form.box label input:focus, .form.box label textarea:focus {
          box-shadow: 0 0 5px #081794;
          outline: none;
          border-color: #DDD; }
      #form.box .gLabel button.submit, #form.box label button.submit, .form.box .gLabel button.submit, .form.box label button.submit {
        outline: none;
        background-color: #081794;
        color: #FFF;
        min-height: 40px;
        border: 0 solid #081794;
        width: 100%;
        letter-spacing: 0.2em; }
      #form.box .gLabel textarea, #form.box label textarea, .form.box .gLabel textarea, .form.box label textarea {
        padding: 8px 0;
        height: 4em; }
    #form.box .accordion > .card, .form.box .accordion > .card {
      overflow: visible;
      background: #FCFCFC; }
  #form .input-group, .form .input-group {
    width: 100%;
    z-index: 0; }
    #form .input-group .btns, .form .input-group .btns {
      position: absolute;
      left: 0;
      z-index: 9;
      height: 38px;
      width: auto; }
      #form .input-group .btns .btn, .form .input-group .btns .btn {
        height: 38px;
        width: 30px;
        padding: 6px;
        position: relative;
        border-radius: 0; }
        #form .input-group .btns .btn:first-child, .form .input-group .btns .btn:first-child {
          border-radius: 4px 0 0 4px; }
    #form .input-group .input-group-btn, .form .input-group .input-group-btn {
      width: 100px; }
      #form .input-group .input-group-btn .btn, .form .input-group .input-group-btn .btn {
        font-size: 11px;
        height: 38px;
        width: 51.5%;
        color: #0D0D0D; }
        #form .input-group .input-group-btn .btn.sel, .form .input-group .input-group-btn .btn.sel {
          color: #FEFFFF;
          background-color: #081794; }
      #form .input-group .input-group-btn select, .form .input-group .input-group-btn select {
        height: 38px;
        font-size: 13px;
        width: 100%;
        border: 1px solid #DDD;
        border-radius: 0 4px 4px 0;
        border-width: 1px 1px 1px 0;
        text-indent: 5px;
        outline: none; }
      #form .input-group .input-group-btn.half, .form .input-group .input-group-btn.half {
        width: 50px; }
        #form .input-group .input-group-btn.half .btn, .form .input-group .input-group-btn.half .btn {
          width: 100%; }
      #form .input-group .input-group-btn.size1, .form .input-group .input-group-btn.size1 {
        width: 40px; }
        #form .input-group .input-group-btn.size1 button, .form .input-group .input-group-btn.size1 button {
          text-align: center;
          font-size: 18px;
          height: 38px;
          width: 40px;
          border: 1px solid #DDD;
          border-radius: 4px 0 0 4px;
          border-width: 1px 0 1px 1px;
          background-color: #FEFFFF;
          outline: none; }
          #form .input-group .input-group-btn.size1 button.sel, .form .input-group .input-group-btn.size1 button.sel {
            color: #FEFFFF;
            background-color: #081794; }
    #form .input-group .form-control, .form .input-group .form-control {
      border-radius: 0; }
    #form .input-group input.form-control, .form .input-group input.form-control {
      text-indent: 65px;
      border-radius: 4px; }
  #form .textField, .form .textField {
    background: white;
    width: 100%; }
    #form .textField div:first-child:before, #form .textField div:first-child:after, #form .textField div:first-child:hover:not(.MuiInput-disabled-8):not(.MuiInput-focused-7):not(.MuiInput-error-10):before, .form .textField div:first-child:before, .form .textField div:first-child:after, .form .textField div:first-child:hover:not(.MuiInput-disabled-8):not(.MuiInput-focused-7):not(.MuiInput-error-10):before {
      border-bottom: 0; }
    #form .textField div:first-child input, .form .textField div:first-child input {
      font-size: 1.2em;
      text-indent: 0.5em;
      padding: 0; }
  #form .form-control:focus, .form .form-control:focus {
    border-color: #081794;
    outline: 0;
    box-shadow: 0 0 3px #081794 !important; }
  #form .mySelect, .form .mySelect {
    color: #0D0D0D;
    font-size: 0.9em;
    outline: none;
    float: none;
    display: inline-block;
    min-width: 220px; }
    #form .mySelect .___control, .form .mySelect .___control {
      border: 1px solid #DDD !important;
      box-shadow: 0 0 0 0 #DDD; }
      #form .mySelect .___control:focus-within, .form .mySelect .___control:focus-within {
        box-shadow: 0 0 3px #081794 !important; }
      #form .mySelect .___control input, .form .mySelect .___control input {
        height: auto;
        outline: none !important;
        box-shadow: 0 0 0 0 #081794 !important; }
    #form .mySelect .___option, .form .mySelect .___option {
      color: #0D0D0D !important;
      background-color: #FEFFFF;
      font-size: 1em; }
      #form .mySelect .___option:hover, .form .mySelect .___option:hover {
        background-color: #f6fafb; }
    #form .mySelect .___indicator-separator, .form .mySelect .___indicator-separator {
      padding: 0;
      height: 100%;
      margin: auto;
      position: inherit; }
    #form .mySelect .___menu ::-webkit-scrollbar, .form .mySelect .___menu ::-webkit-scrollbar {
      width: 10px; }
    #form .mySelect .___menu ::-webkit-scrollbar-track, .form .mySelect .___menu ::-webkit-scrollbar-track {
      background: #f6fafb; }
    #form .mySelect .___menu ::-webkit-scrollbar-thumb, .form .mySelect .___menu ::-webkit-scrollbar-thumb {
      background: #888; }
    #form .mySelect .___menu ::-webkit-scrollbar-thumb:hover, .form .mySelect .___menu ::-webkit-scrollbar-thumb:hover {
      background: #048abf; }
  #form .mySelect, .form .mySelect {
    min-width: 100%; }
  #form .nav-tabs, .form .nav-tabs {
    margin-bottom: 1.3em; }
    #form .nav-tabs > li > a, .form .nav-tabs > li > a {
      color: #888; }
    #form .nav-tabs > li.active > a, .form .nav-tabs > li.active > a {
      color: #081794;
      font-weight: 600; }

.aba {
  min-height: 15em; }
  .aba h4 {
    margin-top: 0.5em;
    margin-bottom: 1.5em; }
    .aba h4::after {
      content: '';
      background-image: linear-gradient(-90deg, #FEFFFF 3%, #081794 51%, #FEFFFF 95%);
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0.8em; }

.w100 {
  width: 100%; }

.iGroup {
  position: relative;
  z-index: 0; }
  .iGroup .fRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    min-width: 2.5em;
    outline: none; }

.intervalos {
  padding: 10px 0;
  height: 53px;
  position: relative;
  border-top: 1px solid #DDD; }
  .intervalos .form-control1 {
    outline: none;
    border: 0 solid;
    min-width: 80px;
    margin-top: 0.4em;
    margin-left: 2.5em;
    font-weight: 600;
    color: #ff8c2e; }
  .intervalos .btn-group .btn {
    width: 60px; }
  .intervalos .off, .intervalos .on {
    top: 0.5em;
    font-weight: 600; }
    .intervalos .off::before, .intervalos .on::before {
      width: 100%;
      color: #d40000;
      text-align: center;
      position: absolute;
      content: "INDISPONIBILIDADE DE ATENDIMENTO"; }
  .intervalos .on::before {
    color: #00d406;
    content: "HORÁRIO DE ATENDIMENTO INTEGRAL"; }

#dialog .err b, #dialog .ok b, form .err b, form .ok b {
  color: #d20000; }

#dialog .err b::after, #dialog .ok b::after, form .err b::after, form .ok b::after {
  content: '\f00d';
  color: #d20000;
  margin: 0 1em;
  font-weight: 900;
  position: absolute;
  font-family: 'Font Awesome 5 Free'; }

#dialog .err .input-group .input-group-btn .btn.sel, #dialog .ok .input-group .input-group-btn .btn.sel, form .err .input-group .input-group-btn .btn.sel, form .ok .input-group .input-group-btn .btn.sel {
  color: #FEFFFF;
  background-color: #d20000; }

#dialog .ok b, form .ok b {
  color: #00a000; }

#dialog .ok b::after, form .ok b::after {
  content: '\f00c';
  color: #00a000; }
