//Cores principais
$cor_1: #0D0D0D;
$cor_2: #048abf;
$cor_3: #081794; //#049dbf;
$cor_4: #0f8c46;
$cor_5: #f2921d;
$cor_D: #f6fafb; //#DEF2F1;
$cor_F: #FEFFFF;
$corO5: rgba(242, 146, 29, 0.5);

//Tons de cinza
$black: #000;
$white: #FFF;
$gray8: #888;
$grayA: #AAA;
$grayB: #BBB;
$grayC: #CCC;
$grayD: #DDD;
$grayE: #EEE;
$grayF4: #F4F4F4;
$grayF8: #F8F8F8;
$grayFC: #FCFCFC;
$black13: rgba(0,0,0,.13);
$black50: rgba(0,0,0,.5);
$aqua50: rgba(0, 255, 255,0.5);
$red20: rgba(150,0,0,.2);

$cor_alert: yellow;
//cores de controle
$cor_red: red;
$cor_err: rgb(210, 0, 0);
$cor_ok : rgb(0, 160, 0);

//cores de status
$cor_R: #d40000;
$cor_O: #ff8c2e;
$cor_G : #00d406;