@import '../color.scss';

#itemsList, .itemList{
    color: $cor_2;
    padding: 1em 0.5em;
    .item{
        padding: 0.5em;
        margin: 0.6em auto;
        background-color: $cor_F;
        border-radius: 10px;
        border: 2px solid $grayE;
        //box-shadow: 3px 3px 8px $grayD;
        position: relative;
        color: $cor_1;
        min-height: 11em;
        &-espec{
            color: $cor_1!important;
            border: 1px solid $grayD;
            padding: 0.3em 1em;
            border-radius: 0.2em;
            margin-bottom: 1em;
        }
        &-doc{
            b{
                color: $cor_1;
            }
        }
        &.flex{
            display: flex;
            min-height: 8em;
            .message{
                flex-grow: 2;
                small{ 
                    font-size:1em;
                    display: block;
                    color:  $gray8;
                    padding: 0 1.5em;
                    margin-bottom: 1em;
                }
            }
        }
        .btns{
            float: right;
            margin: .5em; 
            border:1px solid $grayE;
            border-radius: 4px;
            overflow: hidden;
            button{
                background-color: $grayE;
                border:0 solid $grayD;
                color: $gray8;
                padding: 0.3em;
                min-width: 2.5em;
                &:hover{
                    background-color: $cor_3;
                    color: $cor_F;
                }
            }
        }
        .image {
            background-color: $grayD;
            width: 80px;
            height: 80px;
            float: left;
            margin: 0.5em;
            border-radius: 50%;
            border: 3px solid $cor_F;
            box-shadow: 0 0 3px $grayA;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0%;
                &.sem{
                    height: 110%;
                    margin-top: 5px;
                }
            }
        }
        .titulo{
            padding: 0.1em 1em;
            font-size: 1.3em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin:0.5em auto;
            small{
                color: $gray8
            }
            div{
                margin-bottom: 0.8em;
            }
        }
        .dados{
            display:flex;
            margin-bottom: 0.5em;
            .painel{
                padding:0.5em 2.5em 0.5em 1em;
                .icon{
                    color: $cor_F;
                    background-color: $cor_3;
                    border-radius: 5px;
                    font-size: 0.8em;
                    padding: 0.2em;
                    margin-right: 0.5em;
                    svg{
                        width: 0.8em;
                        height: 0.8em;
                    }
                    &.mail{
                        background-color: $cor_2;
                    }
                }
                small{
                    text-transform: uppercase;
                    color: $gray8;
                    display: block;
                }
                &:last-child{
                    //border-left: 1px solid $grayD
                }
            }
        }
    }
}
.tooltip-container{
    top:-1em!important;
    padding: 0.5em;
    p{
        text-align: right;
        margin: 0.1em 1em;
        font-size: 0.9em;
        label{
            float: left;
            font-size: 0.8em;
            margin-right: 1em;
        }
    }
    
    .tooltip-arrow[data-placement*='top']{
        margin-bottom: -.5em!important;
    }
}
.invert {
    filter: invert(100%);
}

