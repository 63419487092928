@import '../color';

$colWidthMonth: 100%/6;
$colHeightMonth: 5em;
$colWidthWeek: 15%;
$colHeightWeek: 5em;

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin cor1flex {
    color:$cor_1;
    display: flex;
    flex-wrap: wrap;
}

@mixin boxShadow($num) {
    box-shadow: 0 0 $num*8px $black13;
}

.jsc{
    &-events{
        &-sel{
            position: relative;
            padding: 1px;
            margin: 0 0 1em 0;
            background: $grayC;
        }
        &-close{
            position: absolute;
            top: 0.85em;
            right: 0.8em;
            svg{
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
        }
    }
    &-body{
        @include boxShadow(2);
        display: flex;
        padding: 16px;
        background-color: $cor_F;
        &-main{
            width: 75%;
        }
        &-panel{
            width: 25%
        }
    }
    &-block{
        //background-color: $cor_F;
        //padding: 20px;
        transition-duration: 0.25s;
        -webkit-transition-duration: 0.25s;

        .MuiSelect-outlined.MuiSelect-outlined1{
            padding: 10px 32px 9px 10px;
        }
    }
    &-col{
        &-title{
            width: 80%;
            color: $cor_1;
            text-align: center;
            margin: 0 auto!important;
            font-weight: 600!important;
            border-bottom: 1px solid $cor_3;
            padding: {
                top: 1em;
                bottom: 0.5em;
            }
        }
    }
    &-card{
        background-color: $grayD;
        height: 4em;
        font-size: 0.9em;
        border: 2px solid $white;
        display: flex;
        align-items: center;
        justify-content: center;
        &-over{
            color:$cor_5;
            cursor: pointer;
        }
        &.off{
            background-color: $grayE;
            color: $gray8;
            background: repeating-linear-gradient( 135deg, $grayE 0px, $grayE 5px, $grayD 5px, $grayD 7px );
        }
        &.over{
            background-color: $grayE;
            //background: repeating-linear-gradient( 135deg, $grayE 0px, $grayE 5px, $grayC 5px, $grayC 7px );
            color: $grayB;
        }
        &.busy{
            flex-direction: column;
            background-color: $corO5;
            line-height: 0.9em;
            :nth-child(2){
                margin-top: 5px;
            }
            &.aqua{
                background-color: $aqua50;
            }
        }
        &.time{
            align-items: flex-start;
            position: relative;
            background-color: $cor_F;
            &.scale{
                &::before, &::after {
                    content: "";
                    width: 0.5em;
                    top: 0;
                    right: 0.5em;
                    position: absolute;
                    border-bottom: solid 1px $cor_1;
                }
                &::after{
                    top: 50%;
                }
                div{
                    margin-top: -0.6em;
                    font-size: 12px;
                }
            }
        }
        &.free{
            position: relative;
            background-color: $cor_D;
            .green{
                color: $cor_4;
                font-weight: 600;
            }
            &:hover{
                &::before{
                    content: " ";
                    position: absolute;
                    background: $black13;
                    width: 100%;
                    height: 100%;
                }
                &::after{
                    content: attr(alt);
                    font-size: 0.8em;
                    padding: .5em .75em;
                    border-radius: 0.5em;
                    position: absolute;
                    color: $cor_F;
                    background-color: $cor_3;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
    &-toolbar{
        @include cor1flex;
        //padding: 0.5em;
        font-size: 1.1em;
        justify-content: space-between;
        align-items: center;
        //border-bottom:1px solid $grayD;
        &-view{
            button{
                outline: none;
            }
            .active{
                border-bottom: solid 2px $cor_3;
                color: $cor_3;
                font-weight: 600;
            }
        }
        &-head{
            @include boxShadow(2);
            background-color: $cor_F;
            padding: 32px 16px;
            margin: 16px 0;

            .box{
                &-search{
                    &::after{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        content: '\f002';
                        font-family: 'Font Awesome 5 Free';
                        font-weight: bold;
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        top:2px;
                        right: 2px;
                    }
                }
            }
        }
        .desc{ 
            font-size: 1.5em;
            color: $cor_5;
            min-width: 180px;
            text-align: center;
        }
        .next, .prev {cursor: pointer;}
        .options{
            padding: 5px 13px;
            color: $grayA;
            cursor: pointer;
            svg{
                font-size: 0.9em;
            }
            &.active{
                border: 3px solid $cor_5;
                color: $cor_5;
                border-radius: 13px;
                cursor: default;
            }
        }
    }
    &-header{
        @include cor1flex;
        padding: .5em 0;
        &-cell{
            font-weight: 600;
            flex: 1 0 $colWidthMonth;
            text-align: center;
            padding: 5px 0;
            border: 2px solid $white;
            &.active{
                background-color: $cor_3;
                color: $cor_F
            }
            &.size6{
                flex-basis: $colWidthWeek*6;
            }
        }
    }
    &-month{
        &-events{
            margin-top: 2em;
        }
        &-ref{
            color: $cor_1;
            text: {
                align: center;
                transform: uppercase;
            }
        }
        &-view{
            width:100%;
        }
        &-body{
            @include cor1flex;
                border:solid 1px $grayE;
            &-cell{
                flex: 1 0 $colWidthMonth;
                border:solid 1px $grayE;
                min-height: 5em;
                &::before{
                    content: attr(alt);
                    color: #000000;
                    position: absolute;
                }
                span{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
                &.sel{
                    padding: .5em .8em;
                    span{ font-weight: 500;}
                    &:hover{
                        background-color: $grayD;
                    }
                    &.active{
                        position: relative;
                        background-color: transparent;
                        &::before{
                            top:5px;
                            left:7px;
                            padding: 3px 5px;
                            font-size: 0.85em;
                            min-width: 23px;
                            border-radius: 15px;
                            text-align: center;
                            color: $cor_F;
                            background-color: $cor_3;
                        }
                    }
                }
            }
        }
    }
    &-week{
        &-view{
            .jsc-header-cell{
                flex: 1 0 $colWidthWeek;
                cursor: pointer;
                &:hover{
                    letter-spacing:  .05em;
                    color: $cor_5;
                }
                &.active:hover{
                    color: $cor_F;
                }
                &.time{
                    flex: 1 0 $colWidthWeek*2/4;
                }
            }
        }
        &-content{
            @include cor1flex;
        }
        &-col{
            flex: 1 0 $colWidthWeek;
            &.size6{
                flex-basis: $colWidthMonth*6;
            }
            &.time{
                flex: 1 0 $colWidthWeek*2/4;
            }
        }

    }
    &-day{
        &-view{
            .jsc-card, .jsc-header-cell{
                justify-content: left;
                text-align: left;
                text-indent: 1em;
            }
            .jsc-header-cell{
                border-width: 2px 0;
            }
            .jsc-card.free{
                &:hover::after{
                    right: 1em;
                    text-indent: 0;
                    padding: 0.5em 1em;
                }
            }
        }
        &-content{
            @include cor1flex;
            .jsc-card.busy{
                flex-direction: row;
                :nth-child(2){
                    margin-top: 0;
                    //font-size: 1.2em;
                }
            }
        }
        &-col{
            position: relative;
            &.size6{
                flex-basis: $colWidthWeek*6;
            }
            &.size7{
                flex-basis: $colWidthWeek*7;
            }
        }

    }
}


.child-ellipsis{
    min-width: 0; /* or some value */
    width: 100%;
    overflow: hidden;
    .child {
        max-width: 99%;
        padding: 0 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-align: center;
    }
}