@import '../color';

$pageBg: $cor_2;
$formBg: $cor_3;
$bdrClr: $cor_2;


#login{
    background-image: url('../../img/bg_app.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    form{
        //margin-bottom: 8%;
        //margin-top: 2%;
        letter-spacing: 0.1em;
        color: $cor_1;
        font-family: 'Source Sans Pro',sans-serif;
        background-color: $white;
        border: 0 solid $bdrClr;
        border-radius: 5%;
        padding: 2em 0 0 0;
        position: relative;
        box-shadow: 0 1px 0.5em $grayA;
        h2{
            text-transform: uppercase;
            text-align: center;
            color: $cor_1;
            margin-bottom: 1.9em;
            padding-top: 0.1em;
            .falseBg{
                background-color: $cor_F;
                background-image: url(../../img/bg_app.jpg);
                background-position: center;
                display: block;
                position: absolute;
                height: 0.4em;
                width: 2.5em;
                border-radius: 0.3em;
                top: 0.8em;
                left: calc(50% - 1.25em);
                box-shadow: inset 0px 1px .3em $grayA;
            }
        }
        .data{
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 5.5em 2.1em 2em 2.1em;
            background-color: $cor_2;
            border-radius: 0 0 5% 5%;
            border: 2px $white solid;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 8em;
                height: 8em;
                top: -3em;
                left: calc(50% - 4em);
                border-radius: 4em;
                background-color: $cor_2;
                background-color:$cor_2;
                background-image: url('../../img/person.png');
                background-size: 7em;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        label{
            padding: 1.1em 0;
            position: relative;
            font-weight: 500;
            font-size: 0.8em;
            color: $cor_D;
            button{
                display:flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2em;
                padding: 1em;
                text-transform: uppercase;
                letter-spacing: 0.5em;
                width: 100%;
                color: $cor_F;
                background-color: $cor_1;
                border-width: 0;
                border-radius: 0.5em;
                cursor: pointer;
                outline: none;
                img{margin: 0 10px 0}
            }
            .showHide{
                position: absolute;
                z-index: 2;
                font-size: 2em;
                top: 0.9em;
                right: 0.5em;
                cursor:pointer;
                text-transform: uppercase;
                font-weight: 500;
                color: $cor_2;
                &:hover{ color:$cor_3 }
            }
            input{
                color: $cor_1;
                font-size: 1.3em;
                font-family: 'Source Sans Pro',sans-serif;
                letter-spacing: 0.1em;
                outline: none;
                padding: 1.5em;
                width: 240px;
                height: 1.3em;
                padding-right: 3em;
                padding-left: 0.8em;
                border: 0 #ddd solid;
                border-radius: 0.5em;
                background-color: $cor_F;
            }
        }
    }
    .err{
        text-align: center;
        color: $cor_D;
        font-weight: "bold";
        text-transform: uppercase;
        font-size: 0.85em;
    }
}