@import './color';

$menuW: 60px;

html, body{
    height: 100%;
}

@mixin line-after {
    content: '';
    background-image: linear-gradient(-90deg,$cor_F 3%, $cor_3 51%,$cor_F 95%);
    height: 1px;
    width: 100%;
    position: absolute;
    left:0;
}

@mixin inline-relative {
    display: inline-block;
    position: relative;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

body{
    background-color: $cor_D;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    padding: 0;
    
    //disabilita seleção de texto
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    .box{
        &-bordered{
            border: 1px solid $grayC;
            width: 100%;
            padding: 1.5em;
            border-radius: 1em;
            box-shadow: 1px 3px 8px $black13;
            min-height: 200px;
            margin-left: -1em;
            margin-top: 0.5em;
            display: table;
        }
    }
    .cutout{
        top: 0;
        left: 0;
        z-index: 6;
        width: 100%;
        position: absolute;
        &-alert{
            position: absolute;
            //padding: 0.8em 0;
            color: $cor_3;
            font-weight: 600;
            //border-radius: 10px;
            text-align: center;
            //background-color: $cor_alert;
        }
    }

    .MuiChip{
        &-colorPrimary{
            background-color: $cor_3;
        }
    }
    .MuiSelect{
        &-icon{
            top:9px
        }
    }
    .MuiInputLabel-outlined{
        font-size: 0.7em;
        &.MuiInputLabel-shrink{
            display: none;
        }
    }
    .MuiSelect-outlined.MuiSelect-outlined{
        color: $cor_3;
        font-weight: 600;
        font-size: 1.2em;
    }
    .MuiFormGroup-root{
        label{
            margin: 0!important; 
        }
        span{
            position: relative!important;
            color: $cor_1!important;
            padding: 0!important;
            margin: 0;
            font-size: 1em!important;
            &.MuiFormControlLabel-label{ 
                margin-left: 0.2em;
            }
        }
    }
}
.primary{color:$cor_1}
.card{
    &-wait{
        width: 200px;
        padding: 10px;
        min-height: 60px;
        background: $cor_D;
        color: $black;
        position: relative;
        z-index: 1;
        box-shadow: 0 2px 5px $black13;
        margin: 1em auto;
        .edit{
            right: 3em;
            width: 25px;
            height: 25px;
            bottom: 0em; 
            color: $grayA;
            display: block; 
            font-size: 11px;
            position: absolute;
            text-transform: uppercase;
            &:hover{
                color: $cor_3;
                cursor: pointer;
            }
        }
    }
    &-status{
        width: 150px;
        text-align: left;
    }
    &-name{
        @include ellipsis;
        width: 130px;
    }
    &-options{
        position: absolute;
        right: 1em;
    
    }
}

.filter{
    //position: absolute;
    margin-top: -3.3em;
    width: 100%;
    z-index: 2;
    .width-50{
        width: calc(100% - 50px);
        min-width: auto!important;
    }
    &.top12{
        top:12em;
        right: -15px;
        margin:0 0 0 15px;
    }
    .icon{
        color: $cor_1;
        font-size: 21px;
        top: 0.2em;
        position: relative;
        margin-right: 0.5em;
    }
}
.agendaCols{
    display: flex;
    margin-bottom: 1em;
    justify-content:space-evenly;
    .rbc-calendar{
        max-width: 700px;
        margin: 0 auto;
        .rbc-toolbar{
            margin: 2em 0 1em;
        }
    }
    .colDoctors{
        padding:0 1em;
        flex-grow: 1;
        z-index: 2;
        position: relative;
        background: rgba(248,255,248,0.6);
        border-radius: 1em;
        color: $cor_1;
        h4{
            text-align: center;
            margin-bottom: 0.9em;
            color: $cor_2;
            padding-top: .5em;
            letter-spacing: 0.1em;
        }
        .mySelect{  min-width: 100% }
        .doctors{
            display: flex;
            flex-direction: column;
            .dCard{
                padding: 0.5em;
                margin: 0.8em auto 0;
                background-color: $cor_F;
                border-radius: 10px;
                position: relative;
                min-height: 5em;
                &:hover{
                    opacity: .75!important;
                    cursor: pointer;
                }
                .image{
                    background-color: $grayD;
                    width: 60px;
                    height: 60px;
                    margin: 0.2em auto;
                    border-radius: 50%;
                    border: 3px solid $cor_F;
                    box-shadow: 0 0 3px $grayA;
                    position: relative;
                    display: inline-block;
                    .dropdown{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 1em;
                        height: 1em;
                        z-index: 2;
                        .show{
                            .sel::before{
                                content: '\f00c';
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 700;
                                position: absolute;
                                font-size: .8em;
                                left:0.5em;
                            }
                        }
                        .btn{
                            height: 100%;
                            border-radius: .5em;
                            padding: 0;
                            border: 0 solid;
                            display: block;
                            -webkit-appearance: initial;
                            &::after{
                                display: none;
                            }
                        }
                        .dropdown-menu{
                            min-width: 100px;
                            transform: translate3d(-86px, 14px, 0px)!important;
                            & > li > a {
                            padding: 1px 20px;
                            font-size: 0.9em
                            }
                        }
                    }
                    &.on{ .dropdown{ .btn{ background-color: $cor_G; } } }
                    &.busy{ .dropdown{ .btn{ background-color: $cor_R; } } }
                    &.away{ .dropdown{ .btn{ background-color: $cor_O; } } }
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 0%;
                        &.sem{
                            height: 110%;
                            margin-top: 5px;  
                        }
                    }
                }
                .titulo{
                    text-align: center;
                    display: inline-block;
                    margin: 1.1em 0.5em 0.5em;
                }
            }
        }
    }
    .colCalendar{
        flex-grow: 3;
        z-index: 1;
        position: relative;
        .titulo{
            z-index: 1;
            text-align: center;
            color: $cor_5;
            position: absolute;
            width: 100%;
            top: 0.1em;
            letter-spacing: 0.1em;
            text-shadow: 1px 1px 1px $black50;
        }
        &.t15{
            .rbc-timeslot-group{
                height: 6em;
            }
        }
        &.t5{
            .rbc-timeslot-group{
                height: 18em;
            }
        }
        .rbc-addons-dnd{
            .rbc-event{
                width:100%!important;
                left:0!important
            }
        }
    }
    .colEncaixe{
        flex-grow: 1;
        z-index: 2;
        padding: 0 1em;
        color: $cor_1;
        text-align: center;
        background: rgba(248,255,248,0.6);
        border-radius: 1em;
        color: $cor_1;
        h4{
            color: $cor_2;
            padding-top: .5em;
            letter-spacing: 0.1em;
        }
        .titulo{
            text-transform: uppercase;
            padding-bottom: .3em
        }
        .card{
            margin: 0.5em auto;
            border-radius: .5em;
        }
    }
    .rbc-day-slot{
        .rbc-event-label{
            display: none;
        }
        .rbc-events-container{
            margin: auto
        }
    }
}
.main{
    padding-left: $menuW;
    min-width: 800px;
    color: $cor_F;
    background-color: $cor_D;
    .flex{
        display: flex;
        &-col{
            flex-direction: column;
        }
        &-width{
            &-25{ flex: 0 0 25% }
            &-75{ flex: 0 0 75% }
        }
    }
    .list{
        &-body{
            margin: 2.5em 0;
            background-color: $cor_F;
            padding: 2em!important;
            box-shadow: 0 0 10px $black13;
        }
        &-item{
            .item{
                &:hover{
                    cursor: pointer;
                    background-color: $grayF8!important;
                    box-shadow: 3px 3px 10px $black13;
                }
            }
        }
    }
    .header{
        padding: 0.5em 1em;
        background-color: $cor_3;
        font-size:1.5em;
        &-main{
            margin: 1.5em 0;
            color: $cor_1;
            display: flex;
            flex-wrap: wrap;
            font-size: 1.1em;
            justify-content: space-between;
            align-items: center;
            background: $cor_F;
            padding:2em 1em;
            box-shadow: 0 0 10px $black13;
        }
        .logout{
            float: right;
            margin-top: 0.2em;
            margin-left: 1.8em;
            font-size: 0.8em;
            font-weight: 100;
            position: relative;
            cursor: pointer;
            &::before{
                content:"";
                position: absolute;
                display: block;
                height: 2em;
                width: 2px;
                background-color: $cor_F;
                left:-1em;
                top:-0.4em;
            }
        }
        .img{
            margin-right: 0.3em;
        }
    }
    .body{
        margin: 5%;
        //position: relative;
        .title{
            color: $cor_1;
            font-size: 1.5em;
            font-weight: 600;
            //text-transform: uppercase;
            &::after{
                content: "";
                display: block;
                width: 4em;
                height: 0.3em;
                //background-color: $cor_3;
                border-radius: 0.15em;
                margin: 0.3em 0 0 -0.3em;
            }
            svg{
                color: $cor_3;
                font-size: 0.8em;
                position: relative;
                top:-3px;
            }
        }
        .btNew{
            height: 38px;
            color: $white;
            background-color: $cor_2;
            font-size: 1.2em;
            border: 1px solid $white;
            //padding: 0 15px;
            min-width: 80px;
            margin-left: 1em;
            &.chip{
                border-radius: 2em;
                outline: none;
                background-color: $cor_3; 
                small{
                    font-size: 0.7em;
                }
            }
            sub{
                margin-right: 0.2em;
            }
            &:hover{
                background-color: $cor_2;
                //border-color: $cor_3;
            }
            &.wait{
                font-size: 1em;
                width: 100%;
                margin: 0 0 1em 0;
            }
        }
        .filter{
            input, textarea{
                width: auto;
                min-width: 220px;
                height: 38px;
                color: $cor_1;
                border: 1px solid $grayD;
                border-radius: 4px;
                text-indent: 0.1em;
                float: none;
                &:focus{
                    box-shadow: 0 0 5px $cor_3;
                    outline: none;
                }
            }
            .search{
                @include inline-relative;
                &:after{
                    content:'\f002';
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    position: absolute;
                    color: $grayA;
                    right: 0;
                    padding: 0.7em;
                    border-left: 1px solid $grayD
                }
                .clear{
                    position: absolute;
                    color: $grayA;
                    right: 2.5em;
                    padding: 0.7em;
                    &:hover{
                        color: $cor_1;
                        cursor: pointer;
                    }
                }
            }
            .sel-search{
                @include inline-relative;
            }
        }
    }

    @import './forms/myselect';
    .mySelect{
        padding: 0;
        &.border-less{
            .___control{
                border-radius: 0;
                border-width: 0 1px 1px 0!important;
                &:focus-within{
                    box-shadow: none!important;
                }
            }
        }
    }
}
.menu{
    background: $cor_F;
    height: 100%;
    overflow: auto;
    position: fixed;
    z-index: 2;
    top:0;
    left:0;
    box-shadow: 0 0 3px $grayD;
    &-list{
        list-style: none;
        padding: 0;
        color: $grayA;
        padding-left: 0.5em;
        &-item{
            font-weight: 600;
            margin-bottom: 1em;
            .circle{
                background-color: $grayA;
                color: $cor_F;
                display: inline-block;
                width: 20px;
                height: 20px;
                text-align: center;
                border-radius: 10px;
                margin-right: 0.5em;
            }
            &.active{
                color: $cor_4;
                .circle{
                    background-color: $cor_4;
                }
            }
        }
    }
    .dock{
        margin-top: 4em;
        min-width: $menuW;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $cor_1;
        .logo{
            text-align: center;
            width: 100%;
            margin-bottom: 2.5em;
            img{
                width:30px;
                position: absolute;
                top:10px;
                left: calc((100% - 30px) / 2);
            }
            &:after{
                @include line-after;
                top: 75px;
            }
        }
        .info{
            text-align: center;
            margin-bottom: 1em;
            .image{
                background-color: $cor_3;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 3px solid $white;
                box-shadow: 0 0 3px $grayA;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 0%;
                    &.sem{
                        height: 110%;
                        margin-top: 5px;
                    }
                }
             }
            .content{
                p{margin: 0 auto}
                .user{
                    margin-top: 1em;
                    font-size: 1.1em;
                    font-weight: 700;
                }
                .type{
                    margin: 0.5em auto 1.5em auto;
                    font-size: 0.7em;
                    color: $gray8
                }
            }
            &:after{
                @include line-after;
            }
        }
        .item{
            width: 99%;
            margin: 0.5em 0;
            border-radius: 3px;
            cursor: pointer;
            position: relative;
            a{
                text-decoration: none;
                color: $gray8;
                padding: 0.5em 0;
                display: block;
            }
            .img{
                width: 2em;
                text-align: right;
                display: inline-block;
                margin-right: 1em;
                font-size: 1.2em;
            }
            .title{
                font-size: 0.9em;
                text-transform: uppercase;
            }
            &:hover{
                background-color: $grayE;
            }
            &.selected{
                //background-color: $cor_3;
                font-weight: 600;
                border-radius: 0;
                border-right: 2px solid $cor_3;
                a{
                    color: $cor_3;
                    cursor: default;
                }
            }
            &.wait::before{
                content: "";
                display: block;
                position: absolute;
                width: 25px;
                height: 25px;
                background-image: url('../img/loading.gif');
                background-position: center;
                background-size: 25px;
                left:8px;
                top: 50%;
                margin-top: -13px;
            }
        }
    }
    .invert{-webkit-filter: invert(100%); filter:invert(100%)}

    .avatar{
        background-color: $cor_2;
        &-mediun{
            width: 30px;
            height: 30px;
        }
    }

    .user{
        &-options{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            min-height: 100px;

        }
    }
    
}

$crtn_clr: rgba(100,100,100,0.5);
#cortina{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $crtn_clr;
    z-index: 9999999;
    transition: color 3s;
    img{
        &.wait{height: 6em;}
    }
}


// Novas classes
.cor5           { color: $cor_5 }
.big            { font-size: 15em }
.dark           { color: $black13 }
.inlineBlock    { display: inline-block }
.floatRight     { float: right }
.rightMenu      { @extend .floatRight}
.agendaOFF      {
    #agenda{
        position: relative;
        filter: opacity(0.2);
        &::after{
            content: "\f05e";
            left:0;
            top: 4.5rem;
            height: calc(100% - 4.5rem);
            width: 100%;
            display: block;
            position: absolute;
            background-color: $black13;
            border-radius: 0 0 7px 7px;
            z-index: 4;
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 35em;
            text-align: center;
            color: $grayA;
        }
    }
}

.search{
    display: inline;
    .input-group {
        display: initial;
        //.form-control, .input-group-btn{ display: initial }
    }
    &::after{
        z-index: 2;
    }
}
.hide{display: none}


.divider{
    position: relative;
    width: 100%;
    margin: 1em 0;
    &::after{
        @include line-after
    }
}