
.textField{
  background: white;
  width: 100%;
  div:first-child{
    &:before, &:after,
    &:hover:not(.MuiInput-disabled-8):not(.MuiInput-focused-7):not(.MuiInput-error-10):before{
      border-bottom: 0
    }
    input{
      font-size:1.2em;
      text-indent: 0.5em;
      padding: 0
    }
  }
}
.form-control:focus {
  border-color: $cor_3;
  outline: 0;
  box-shadow: 0 0 3px $cor_3!important;
}
.mySelect{
    color:$cor_1;
    font-size: 0.9em;
    outline:none;
    float: none;
    display: inline-block;
    min-width: 220px;
    .___control{
        border:1px solid $grayD!important;
        box-shadow: 0 0 0 0 $grayD;
        &:focus-within{box-shadow: 0 0 3px $cor_3!important;}
        input{height: auto;outline:none!important;box-shadow: 0 0 0 0 $cor_3!important}
    }
    .___option{
        color: $cor_1!important;
        background-color:$cor_F;
        font-size: 1em;
        &:hover{
            background-color: $cor_D;
        }
    }
    .___indicator-separator{
        padding: 0;
        height: 100%;
        margin: auto;
        position: inherit;
    }
    .___menu{
        ::-webkit-scrollbar {
            width: 10px;
          }
          ::-webkit-scrollbar-track {
            background: $cor_D; 
          }
          ::-webkit-scrollbar-thumb {
            background: $gray8; 
          }
          ::-webkit-scrollbar-thumb:hover {
            background: $cor_2; 
          }
    }
}